// $font-size-0, $font-size-1, $line-height-0, $line-height-1, $letter-spacing-0, $letter-spacing-1

.responsive-text-12-16 {
  @include responsiveText(12, 16, 18, 24, -0.36, -0.48);
}

.responsive-text-14-16 {
  @include responsiveText(14, 16, 21, 24, -0.42, -0.48);
}

.responsive-text-15-16 {
  @include responsiveText(15, 16, 22.5, 24, -0.45, -0.48);
}

.responsive-text-16-18 {
  @include responsiveText(16, 18, 24, 27, -0.48, -0.54);
}

.responsive-text-24-32 {
  @include responsiveText(24, 32, 33.6, 38.4, -0.72, -0.96);
}

.responsive-text-24-40 {
  @include responsiveText(24, 40, 33.6, 48, -0.72, -1.2);
}

.responsive-text-32-44 {
  @include responsiveText(32, 44, 38.4, 52.8, -0.96, -1.32);
}

.responsive-text-32-56 {
  @include responsiveText(32, 56, 38.4, 67.2, -0.96, -1.68);
}
