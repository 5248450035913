body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &.body-no-scroll {
    overflow: hidden;
    position: fixed;
    width: 100%;
    height: 100%;
  }
}


