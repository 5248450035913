// $emblaCustom_adding: calc(100vw * .15);

.tab-navigator-embla {
  overflow: hidden;
  padding-left: 24px;
  padding-right: 24px;
  @include media-breakpoint-between(sm, lg) {
    padding-left: 64px;
    padding-right: 64px;
  }
  @include media-breakpoint-between(lg, xl) {
    padding-left: 64px;
    padding-right: 64px;
  }
  @include media-breakpoint-up(xl) {
    padding-left: 128px;
    padding-right: 128px;
  }

  .embla__container {
    display: flex;
  }

  .embla__slide {
    flex: 0 0 100%;
    min-width: 0;
    margin-inline-end: 6px; /* Space after the slide */
    margin-inline-start: 6px; /* Space before the slide */
    @include media-breakpoint-between(sm, lg) {
      margin-inline-end: 20px;
      margin-inline-start: 20px;
    }
    @include media-breakpoint-between(lg, xl) {
      margin-inline-end: 20px;
      margin-inline-start: 20px;
    }
    @include media-breakpoint-up(xl) {
      margin-inline-end: 20px;
      margin-inline-start: 20px;
    }
  }
}
