#alterAnywhereHero {
  object-position: 65% 75%;
  @include media-breakpoint-between(sm, lg) {
    object-position: 50% 75%;
  }
  @include media-breakpoint-between(lg, xl) {
    object-position: 50% 75%;
  }
  @include media-breakpoint-up(xl) {
    object-position: 50% 55%;
  }
}

