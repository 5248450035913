.carousel-embla {
  max-width: 48rem;
  margin: auto;
  --slide-height: 19rem;
  --slide-spacing: 1rem;
  --slide-size: 100%;

  .embla__viewport {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
    top: 0;
  }
  .embla__container {
    display: flex;
    touch-action: pan-y pinch-zoom;
    margin-left: calc(var(--slide-spacing) * -1);
  }
  .embla__slide {
    transform: translate3d(0, 0, 0);
    flex: 0 0 var(--slide-size);
    min-width: 0;
    padding-left: var(--slide-spacing);
  }
  .embla__slide__image {
    // box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
    border-radius: 0;
    @include media-breakpoint-up(sm) {
      border-radius: 0.5rem;
    }
    // font-size: 4rem;
    // font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    // height: var(--slide-height);
    user-select: none;
  }
  .embla__buttons {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    // display: grid;
    // grid-template-columns: repeat(2, 1fr);
    // gap: 0.6rem;
    // align-items: center;
  }
  .embla__button {
    -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
    -webkit-appearance: none;
    appearance: none;
    background-color: #FAF8F7;
    touch-action: manipulation;
    display: inline-flex;
    text-decoration: none;
    cursor: pointer;
    border: 0;
    padding: 0;
    margin: 0;
    box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
    width: 2rem;
    height: 2rem;
    z-index: 1;
    color: var(--text-body);
    align-items: center;
    justify-content: center;
    position: absolute;
    top: calc(50% - 1rem);
    border-radius: 4px;
    backdrop-filter: blur(2px);
    &.embla__button--prev {
      left: 1rem;
    }

    &.embla__button--next {
      right: 1rem
    }
  }
  .embla__button:disabled {
    color: var(--detail-high-contrast);
  }
  .embla__button__svg {
    width: 35%;
    height: 35%;
  }

}

.carousel-embla-thumbs {
  --thumbs-slide-spacing: 1rem;
  --thumbs-slide-height: 5rem;
  margin-top: var(--thumbs-slide-spacing);

  .embla-thumbs__viewport {
    overflow: hidden;
  }
  .embla-thumbs__container {
    display: flex;
    flex-direction: row;
    margin-left: calc(var(--thumbs-slide-spacing) * -1);
  }
  .embla-thumbs__slide {
    flex: 0 0 auto;
    min-width: 0;
    padding-left: var(--thumbs-slide-spacing);
  }
  @media (min-width: 576px) {
    .embla-thumbs__slide {
      flex: 0 0 auto;
    }
  }
  .embla-thumbs__slide__image {
    border-radius: 0.5rem;
    -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    touch-action: manipulation;
    display: inline-flex;
    text-decoration: none;
    cursor: pointer;
    border: 0;
    padding: 0;
    margin: 0;
    aspect-ratio: 1;
    // box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
    // font-size: 1.8rem;
    // font-weight: 600;
    // color: var(--detail-high-contrast);
    align-items: center;
    justify-content: center;
    height: var(--thumbs-slide-height);
    width: 100%;
  }
  .embla-thumbs__slide--selected .embla-thumbs__slide__image {
    border: 2px solid rgba(var(--tw-alter-black), 0.8);
  }

  // .embla-thumbs__slide--selected .embla-thumbs__slide__image {
  //   color: var(--text-body);
  // }
}
