@mixin responsiveText(
  $font-size-0: null,
  $font-size-1: null,
  $line-height-0: null,
  $line-height-1: null,
  $letter-spacing-0: null,
  $letter-spacing-1: null,
) {
  font-size: #{$font-size-0}px;
  line-height: #{$line-height-0}px;
  letter-spacing: #{$letter-spacing-0}px;

  @include media-breakpoint-between(sm, md) {
    font-size: breakpoint-linear-interpolation(
      map-get($grid-breakpoints-number, md),
      $font-size-0,
      $font-size-1
    );
    line-height: breakpoint-linear-interpolation(
      map-get($grid-breakpoints-number, md),
      $line-height-0,
      $line-height-1
    );
    letter-spacing: breakpoint-linear-interpolation(
      map-get($grid-breakpoints-number, md),
      $letter-spacing-0,
      $letter-spacing-1
    );
  }

  @include media-breakpoint-between(md, lg) {
    font-size: breakpoint-linear-interpolation(
      map-get($grid-breakpoints-number, lg),
      $font-size-0,
      $font-size-1
    );
    line-height: breakpoint-linear-interpolation(
      map-get($grid-breakpoints-number, lg),
      $line-height-0,
      $line-height-1
    );
    letter-spacing: breakpoint-linear-interpolation(
      map-get($grid-breakpoints-number, lg),
      $letter-spacing-0,
      $letter-spacing-1
    );
  }

  @include media-breakpoint-up(lg) {
    font-size: #{$font-size-1}px;
    line-height: #{$line-height-1}px;
    letter-spacing: #{$letter-spacing-1}px;
  }

}
