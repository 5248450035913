@layer components {
  .width-1440 {
    @apply max-w-[1440px] w-full mx-auto;
  }

  .h-screen-header {
    /* 56px is header height no fill*/
    @apply h-[calc(100vh_-_58px)] mt-[58px];
  }

  .text-earmark {
    @apply text-xs text-alter-black/60 uppercase;
  }
  .min-h-screen-header {
    /* 56px is header height no fill*/
    @apply min-h-[calc(100vh_-_58px)] mt-[58px];
  }

  .button-primary {
    @apply text-lg font-ModernGothic h-fit transition-colors duration-200 border-2 border-alter-bone bg-alter-bone text-alter-black disabled:border-alter-bone-40 disabled:bg-alter-bone-40 disabled:text-alter-black-50 disabled:cursor-not-allowed hover:bg-alter-bone-40 hover:text-alter-black-50 py-3.5 px-4 rounded-full cursor-pointer;
  }

  .button-secondary {
    @apply text-lg font-ModernGothic h-fit transition-colors duration-200 bg-inherit border-2 border-solid border-alter-bone text-alter-bone disabled:border-alter-bone-40 disabled:text-alter-bone-40 disabled:cursor-not-allowed hover:bg-alter-bone hover:text-alter-black py-3.5 px-4 rounded-full cursor-pointer;
  }

  .button-outline-dark {
    @apply text-lg font-ModernGothic h-fit transition-colors duration-200 bg-inherit border border-solid border-alter-black text-alter-black disabled:border-alter-bone-40 disabled:text-alter-bone-40 disabled:cursor-not-allowed hover:text-alter-black py-3.5 px-4 rounded-full cursor-pointer hover:bg-alter-black hover:text-alter-bone;
  }

  .button-outline-error {
    @apply text-lg font-ModernGothic h-fit transition-colors duration-200 bg-inherit border-2 border-solid border-alter-error text-alter-error disabled:bg-alter-red-60 disabled:border-alter-bone-40 disabled:text-alter-bone-40 disabled:cursor-not-allowed hover:bg-alter-bone py-3.5 px-4 rounded-full cursor-pointer;
  }

  .button {
    /* apply radius and width in classname outside rounded-{size} width-{x}*/
    @apply text-lg font-ModernGothic transition-colors duration-200 border-2 border-alter-black bg-alter-black text-alter-bone disabled:opacity-70 hover:opacity-80 py-3.5 px-4 rounded-full;
  }

  .button-light {
    /* apply radius and width in classname outside rounded-{size} width-{x}*/
    @apply text-lg text-center font-ModernGothic transition-colors duration-200 border-2 border-alter-bone bg-alter-bone text-alter-black disabled:bg-alter-bone-40 disabled:text-alter-black-50 hover:bg-alter-bone-80 py-3.5 px-4 rounded-full;
  }

  .button-error {
    @apply text-lg font-ModernGothic transition-colors duration-200 border-2 border-alter-red bg-alter-red disabled:bg-alter-red-60 disabled:text-alter-bone-70 text-alter-bone py-3.5 px-4 rounded-full;
  }

  .button-error-outline {
    @apply text-lg font-ModernGothic h-fit transition-colors duration-200 bg-inherit border-2 border-solid border-alter-error text-alter-error disabled:bg-alter-red-60 disabled:border-alter-bone-40 disabled:text-alter-bone-40 disabled:cursor-not-allowed hover:bg-alter-bone py-3.5 px-4 rounded-full cursor-pointer;
  }

  .button-transparent {
    /* apply radius and width in classname outside rounded-{size} width-{x}*/
    @apply text-lg font-ModernGothic transition-colors duration-200 text-alter-bone disabled:bg-alter-black-8 disabled:text-alter-bone-40 hover:bg-alter-bone hover:text-alter-black py-3.5 px-4 rounded-full;
  }

  .button-alternate {
    /* apply radius and width in classname outside rounded-{size} width-{x}*/
    @apply text-lg font-ModernGothic transition-colors duration-200 border-2 border-alter-bone bg-alter-bone text-alter-black disabled:bg-alter-black-8 disabled:text-alter-bone-40 disabled:border-alter-bone-40 hover:bg-alter-black-4 py-3.5 px-4 rounded-full;
  }

  .button-thin {
    @apply text-base font-ModernGothic transition-colors duration-200 border-2 border-alter-black bg-alter-black text-alter-bone disabled:opacity-70 hover:opacity-80 py-3 px-4 rounded-full;
  }

  .button-ghost {
    /* apply radius and width in classname outside rounded-{size} width-{x}*/
    @apply text-lg font-ModernGothic transition-colors duration-200 border-2 border-alter-black-8 bg-alter-black-8 text-alter-black disabled:bg-alter-black-8 disabled:text-alter-black-40 hover:bg-alter-gray py-3.5 px-4 rounded-full;
  }

  .divider {
    /* apply width in classname outside border-b-{x} */
    @apply border-b border-solid border-b-alter-black w-full;
  }

  .divider-vertical {
    @apply border-l border-solid border-l-alter-black h-full;
  }

  .divider-ghost {
    /* apply width in classname outside border-b-{x} */
    @apply border-b border-solid border-b-alter-black-20 w-full;
  }

  .error {
    @apply text-alter-error text-sm p-[8px];
  }

  .dna-btn-sizing {
    @apply md:text-lg font-ModernGothic rounded-full border-2 px-3.5 md:px-7 pt-[7.5px] pb-[8.5px] md:pt-[12.5px] md:pb-[13.5px] inline-block leading-[1];
  }

  .dna-btn {
    @apply text-lg font-ModernGothic border-2 border-alter-bone rounded-full bg-alter-bone px-8 text-alter-black pt-[10px] pb-[11px] inline-block cursor-pointer canhover:hover:border-alter-bone canhover:hover:bg-alter-bone/0 canhover:hover:text-alter-bone transition-colors duration-200;
  }

  .dna-btn-outline {
    @apply text-lg font-ModernGothic border-2 border-alter-bone rounded-full px-8 text-alter-bone pt-[10px] pb-[11px] inline-block cursor-pointer canhover:hover:bg-alter-bone canhover:hover:text-alter-black;
  }

  .dna-btn-sm {
    @apply rounded-full border-2 border-alter-bone bg-alter-bone px-4 text-alter-black py-2 pb-[9px] inline-block canhover:hover:bg-black canhover:hover:text-alter-bone;
  }

  .dna-buy-btn {
    @apply md:text-lg font-ModernGothic rounded-full border-2 border-alter-bone bg-alter-bone px-3.5 md:px-7 text-alter-black pt-[7.5px] pb-[8.5px] md:pt-[12.5px] md:pb-[13.5px] inline-block leading-[1] transition-colors duration-200 canhover:hover:bg-alter-black canhover:hover:text-alter-bone canhover:hover:border-alter-black;
  }

  .dna-buy-btn-black {
    @apply md:text-lg font-ModernGothic rounded-full border-2 border-alter-black bg-alter-black px-3.5 md:px-7 text-alter-bone pt-[7.5px] pb-[8.5px] md:pt-[12.5px] md:pb-[13.5px] inline-block leading-[1] transition-colors duration-200 canhover:hover:bg-alter-bone canhover:hover:text-alter-black canhover:hover:border-alter-bone;
  }

  .dna-buy-btn-outline {
    @apply md:text-lg font-ModernGothic rounded-full border-2 border-alter-bone bg-alter-bone px-3.5 md:px-7 text-alter-black pt-[7.5px] pb-[8.5px] md:pt-[12.5px] md:pb-[13.5px] inline-block leading-[1] transition-colors duration-200 canhover:hover:bg-alter-black/0 canhover:hover:text-alter-bone canhover:hover:border-alter-bone;
  }

  .dna-buy-btn-outline-inverted {
    @apply md:text-lg font-ModernGothic rounded-full border-2 border-alter-bone px-3.5 md:px-7 canhover:hover:text-alter-black pt-[7.5px] pb-[8.5px] md:pt-[12.5px] md:pb-[13.5px] inline-block leading-[1] transition-colors duration-200 canhover:hover:bg-alter-bone bg-alter-black/0 text-alter-bone;
  }

  .dna-btn-black {
    @apply text-lg font-ModernGothic border-2 border-alter-black rounded-full bg-alter-black px-8 text-alter-bone pt-[10px] pb-[11px] inline-block cursor-pointer canhover:hover:bg-alter-bone/0 canhover:hover:text-alter-black;
  }

  .dna-btn-black-outline {
    @apply text-lg font-ModernGothic border-2 border-alter-black rounded-full px-8 text-alter-black pt-[10px] pb-[11px] inline-block cursor-pointer canhover:hover:bg-alter-black canhover:hover:text-alter-bone;
  }

  .dna-btn-black-solid {
    @apply text-lg font-ModernGothic border-2 border-alter-black rounded-full bg-alter-black px-8 text-alter-bone pt-[10px] pb-[11px] inline-block cursor-pointer canhover:hover:bg-alter-bone canhover:hover:text-alter-black canhover:hover:border-alter-bone transition-colors duration-200;
  }

  .dna-btn-square {
    @apply text-lg font-ModernGothic rounded-full px-8 text-alter-bone pt-[12px] pb-[14px] inline-block cursor-pointer border-2 border-alter-bone canhover:hover:bg-alter-bone canhover:hover:text-alter-black;
  }

  .dna-btn-square-inverted {
    @apply text-lg font-ModernGothic rounded-full px-8 pt-[12px] pb-[14px] inline-block cursor-pointer border-2 border-alter-bone bg-alter-bone text-alter-black canhover:hover:text-alter-bone canhover:hover:bg-[transparent];
  }

  .slideshow-arrow-right,
  .slideshow-arrow-left {
    @apply bg-alter-black rounded-full justify-center items-center w-14 h-14 hidden md:flex absolute;
    transition:
      opacity 500ms,
      transform 300ms;
    top: calc((100vw - var(--scrollbar-width) - 160px) / 8 - (56px / 2));
  }

  .slideshow-arrow-left {
    @apply canhover:hover:-translate-x-0.5 rotate-180 left-20;
  }

  .slideshow-arrow-right {
    @apply canhover:hover:translate-x-0.5 right-20;
  }

  .pagination-arrow-container {
    @apply w-12 h-12 rounded-full bg-alter-black/10 hover:bg-alter-black/20;
  }

  .mux-cover {
    --media-object-fit: cover;
    --media-object-position: center;
    --controls: none;
    --media-background-color: transparent;
    @apply h-full w-full object-cover;
  }

  .mux-contain {
    --media-object-fit: contain;
    --media-object-position: center;
  }

  .mux-player {
    --controls: none;
    pointer-events: none;
  }

  /* hide loading spinner */
  mux-player::part(centered-layer) {
    display: none;
  }

  mux-player::part(poster-layer) {
    display: none;
  }

  .mux-player-controls-wrapper mux-player::part(centered-layer) {
    display: flex;
  }

  .header-background-gradient {
    background: linear-gradient(
      to bottom,
      hsl(0, 0%, 0%) 0%,
      hsla(0, 0%, 0%, 0.987) 18.6%,
      hsla(0, 0%, 0%, 0.951) 34.4%,
      hsla(0, 0%, 0%, 0.896) 47.8%,
      hsla(0, 0%, 0%, 0.825) 59%,
      hsla(0, 0%, 0%, 0.741) 68.1%,
      hsla(0, 0%, 0%, 0.648) 75.5%,
      hsla(0, 0%, 0%, 0.55) 81.3%,
      hsla(0, 0%, 0%, 0.45) 85.9%,
      hsla(0, 0%, 0%, 0.352) 89.3%,
      hsla(0, 0%, 0%, 0.259) 91.9%,
      hsla(0, 0%, 0%, 0.175) 93.8%,
      hsla(0, 0%, 0%, 0.104) 95.4%,
      hsla(0, 0%, 0%, 0.049) 96.8%,
      hsla(0, 0%, 0%, 0.013) 98.2%,
      hsla(0, 0%, 0%, 0) 100%
    );
  }

  .dna-trait-table-background-gradient {
    background-image: linear-gradient(rgba(0, 0, 0, 0), black 300px);
  }

  .pdp-background-gradient {
    background: linear-gradient(
        #000 0%,
        rgba(8, 9, 8, 0.588542) 54.78%,
        rgba(164, 167, 255, 0.2) 140.03%
      ),
      linear-gradient(0deg, #1a1818, #1a1818);
  }

  .card3d {
    perspective: 1000px;
    transform-style: preserve-3d;
  }

  .dna-trait-bulletpoints ol {
    counter-reset: item;
    list-style-type: none;
    padding-left: 0.75em;
  }

  .dna-trait-bulletpoints li {
    padding-left: 0.75em;
    text-indent: -0.75em;
    margin-bottom: 1em;
  }

  .dna-trait-bulletpoints li::before {
    content: counter(item);
    counter-increment: item;
    display: inline-block;
    width: 0.75em;
    @apply text-alter-bone;
  }

  .input-square {
    @apply p-4 mt-1 rounded-md border border-alter-black-20 w-full bg-transparent;
  }

  .drag-indicator {
    @apply h-1 w-16 rounded-full bg-alter-black-10 mx-auto;
  }

  .modal-input {
    @apply border border-alter-black-10 rounded-lg px-4 py-2 mt-1 w-full font-light;
  }

  .error {
    @apply text-alter-error text-xs px-0 font-light;
  }
}

@layer base {
  html,
  body {
    scroll-behavior: smooth;
    max-width: 100vw;
    color: black;
    @apply bg-black;
  }

  body {
    @apply font-ModernGothic;
    min-height: -webkit-fill-available;
  }

  h1 {
    @apply font-ModernGothic;
    font-size: 50px;
    font-weight: 400;
    line-height: 50px;
    letter-spacing: 0em;
  }

  h2 {
    @apply font-ModernGothic;
    font-size: 32px;
    font-weight: 400;
    line-height: 35px;
    letter-spacing: 0em;
  }

  h3 {
    @apply font-ModernGothic;
    font-size: 24px;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0em;
  }

  h4 {
    @apply font-ModernGothic;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
  }

  footer {
    padding-bottom: calc(1em + env(safe-area-inset-bottom));
  }
}

@layer utilities {
  .modernGothicTrial-light {
    @apply font-ModernGothicTrial font-light antialiased;
  }

  .eased-gradient {
    /* eased gradient prevents hard edges normal gradients have */
    /* generated using this editor: https://larsenwork.com/easing-gradients/#editor */
    background: linear-gradient(
      to top,
      hsl(0, 0%, 0%) 0%,
      hsla(0, 0%, 0%, 0.987) 8.1%,
      hsla(0, 0%, 0%, 0.951) 15.5%,
      hsla(0, 0%, 0%, 0.896) 22.5%,
      hsla(0, 0%, 0%, 0.825) 29%,
      hsla(0, 0%, 0%, 0.741) 35.3%,
      hsla(0, 0%, 0%, 0.648) 41.2%,
      hsla(0, 0%, 0%, 0.55) 47.1%,
      hsla(0, 0%, 0%, 0.45) 52.9%,
      hsla(0, 0%, 0%, 0.352) 58.8%,
      hsla(0, 0%, 0%, 0.259) 64.7%,
      hsla(0, 0%, 0%, 0.175) 71%,
      hsla(0, 0%, 0%, 0.104) 77.5%,
      hsla(0, 0%, 0%, 0.049) 84.5%,
      hsla(0, 0%, 0%, 0.013) 91.9%,
      hsla(0, 0%, 0%, 0) 100%
    );
  }

  ::-webkit-search-cancel-button {
    appearance: none;
    width: 10px;
    height: 10px;
    border: none;
    background: url("/icons/x-black.svg");
    background-size: contain;
  }

  /* Date picker color invert */
  ::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }

  ::-webkit-date-and-time-value {
    text-align: left;
  }

  .noise {
    position: fixed;
    left: 0;
    top: 0;
    background-image: url(/test/noise2.png);
    pointer-events: none !important;
    background-size: 250px 250px;
    animation: noiseTextureAnim 0.55s steps(1) infinite;
    will-change: background-position;
    backface-visibility: hidden;
    transform: translateZ(0);
    width: 100%;
    height: calc(100% + 100px);
    z-index: 100;
  }

  @keyframes noiseTextureAnim {
    0% {
      background-position: 0 0;
    }

    10% {
      background-position: 10px 10px;
    }

    20% {
      background-position: 150px 20px;
    }

    30% {
      background-position: 125px 125px;
    }

    40% {
      background-position: 40px 170px;
    }

    50% {
      background-position: 125px 125px;
    }

    60% {
      background-position: 90px 10px;
    }

    70% {
      background-position: 60px 100px;
    }

    80% {
      background-position: 20px 70px;
    }

    90% {
      background-position: 120px 80px;
    }

    to {
      background-position: 0 250px;
    }
  }

  .lighten-font-weight {
    -moz-osx-font-smoothing: grayscale;
    /* Firefox */
    -webkit-font-smoothing: antialiased;
    /* WebKit  */
  }

  .jump {
    animation: jump 2s ease infinite alternate;
  }

  @keyframes jump {
    0% {
      transform: translate3d(0, 10px, 0);
    }

    100% {
      transform: translate3d(0, 0px, 0);
    }
  }

  .slick-scrollbar {
    /* Foreground, Background */
    scrollbar-color: #999 #333;
    overscroll-behavior: contain;
  }

  .slick-scrollbar::-webkit-scrollbar {
    width: 5px;
    /* height: 5px; */
  }

  .slick-scrollbar::-webkit-scrollbar-thumb {
    /* Foreground */
    background: rgba(100, 100, 100, 0.5);
    border-radius: 10px;
  }

  .slick-scrollbar::-webkit-scrollbar-track {
    /* Background */
    background: #333;
    border-radius: 10px;
  }

  .marquee-wrap {
    mask-image: linear-gradient(
      90deg,
      transparent,
      black 10px,
      black calc(100% - 10px),
      transparent
    );
  }

  .marquee-first {
    padding: 0 1px;
    animation: marquee 15s linear infinite;
  }

  .marquee-second {
    position: absolute;
    padding: 0 1px;
    top: 0;
    left: 0;
    animation: marqueeAlt 15s linear infinite;
  }

  @keyframes marquee {
    0% {
      transform: translateX(0%);
    }

    100% {
      transform: translateX(-100%);
    }
  }

  @keyframes marqueeAlt {
    0% {
      transform: translateX(100%);
    }

    100% {
      transform: translateX(0%);
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(359deg);
    }
  }

  .fadeBottom {
    mask-image: linear-gradient(to top, transparent 10%, white 30%);
  }

  /* only for menu background */
  .fadeBottom2 {
    mask-image: linear-gradient(to top, transparent 0px, white 100px);
  }

  .magic-bullet {
    transform: translate3d(0, 0, 0);
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  section {
    scroll-margin-top: 2rem;
  }
}

.truemed-instructions {
  margin-top: -55px;
}

.detail-outcome p {
  font-family: var(--modern-gothic), sans-serif;
  font-weight: 300;
  font-size: 20px;
}

.detail-outcome ol {
  font-family: var(--modern-gothic), sans-serif;
  font-weight: 300;
  font-size: 16px;
}

.single-detail-outcome p {
  font-family: var(--modern-gothic), sans-serif;
  font-weight: 300;
  font-size: 16px;
  @apply text-alter-bone/80;
}
