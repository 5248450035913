.image-wrapper-darken-20::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  background: rgba(0, 0, 0, 0.2)
}

#stopGuessingHero {
  object-position: 50% 50%;
  object-fit: cover;
  @include media-breakpoint-between(sm, md) {
    object-position: 70% 10%;
    object-fit: cover;
  }
  @include media-breakpoint-between(md, lg) {
    object-position: 50% 10%;
    object-fit: cover;
  }
  @include media-breakpoint-between(lg, xl) {
    object-position: 50% 10%;
    object-fit: cover;
  }
  @include media-breakpoint-up(xl) {
    object-position: 50% 10%;
    object-fit: cover;
  }
}


