#discoverYourselfHero {
  object-position: 87% 50%;
  @include media-breakpoint-between(sm, lg) {
    object-position: 80% 75%;
  }
  @include media-breakpoint-between(lg, xl) {
    object-position: 50% 55%;
  }
  @include media-breakpoint-up(xl) {
    object-position: 50% 55%;
  }
}

